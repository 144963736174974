import {
  DateTimeFormats,
  adjustDateByIntervals,
  endOf,
  formatDateTime,
  getCurrentTimezone,
  getTimezoneAbbreviation,
  isDateSame,
  toTimezone,
} from './dateTime';

type Timestamp = Date | string;

const getTimezoneString = (
  timezone: string,
  date: Date,
  browserTime?: string,
) =>
  browserTime && browserTime !== timezone
    ? ` (${getTimezoneAbbreviation(date, timezone)})`
    : '';

export const formatEta = (
  timestamp: Timestamp,
  timezone: string = getCurrentTimezone(),
  dateNow: Date = new Date(),
  browserTime: string = getCurrentTimezone(),
) => {
  const then = toTimezone(timestamp, timezone);
  const now = toTimezone(dateNow, timezone);
  const tomorrow = adjustDateByIntervals(1, 'day', now);

  const timezoneStr = getTimezoneString(timezone, then, browserTime);

  if (isDateSame(then, now, 'day')) {
    return `${formatDateTime(then, DateTimeFormats.TIME)}${timezoneStr}`;
  }
  if (isDateSame(then, tomorrow, 'day')) {
    return `${formatDateTime(
      then,
      DateTimeFormats.TIME,
    )} tomorrow${timezoneStr}`;
  }

  return `${formatDateTime(then, DateTimeFormats.LONG_ALT)}${timezoneStr}`;
};

export const formatEtaFullDay = (
  timestamp: Timestamp,
  timezone: string = getCurrentTimezone(),
  browserTime: string = getCurrentTimezone(),
) => {
  const then = toTimezone(timestamp, timezone);
  const timezoneStr = getTimezoneString(timezone, then, browserTime);

  return `${formatDateTime(then, DateTimeFormats.LONG_ALT)}${timezoneStr}`;
};

export const formatFullDayAndYear = (timestamp: Timestamp) =>
  formatDateTime(timestamp, DateTimeFormats.CALENDAR);

export const formatAwayModeEta = (
  timestamp: Timestamp,
  timezone: string = getCurrentTimezone(),
  dateNow: Date = new Date(),
  browserTime: string = getCurrentTimezone(),
) => {
  const then = toTimezone(timestamp, timezone);
  const now = toTimezone(dateNow, timezone);
  const tomorrow = adjustDateByIntervals(1, 'day', now);
  const nextWeek = endOf(adjustDateByIntervals(6, 'day', now), 'day');

  const timezoneStr = getTimezoneString(timezone, then, browserTime);

  if (isDateSame(then, now, 'day')) {
    return `${formatDateTime(then, DateTimeFormats.TIME)}${timezoneStr}`;
  }
  if (isDateSame(then, tomorrow, 'day')) {
    return `tomorrow${timezoneStr}`;
  }
  if (then < nextWeek) {
    return `${formatDateTime(then, DateTimeFormats.DAY)}${timezoneStr}`;
  }

  return `${formatDateTime(then, DateTimeFormats.SHORT_DATE)}${timezoneStr}`;
};
