/* eslint no-bitwise: 0 */
/* eslint no-mixed-operators: 0 */
/*
 *
 * Modified version of UUID/GUID generator from http://stackoverflow.com/a/8809472/98028
 *
 */
const UUID = () => {
  let d = Math.floor(new Date().getTime() / 1000);
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export { UUID };
