// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import type { JwtPayload } from 'jwt-decode';

const auth0Domains = [
  'https://dev-fb5jk1uw4c7wq8zg.us.auth0.com/',
  'https://login.numa.com/',
];

export const testIsAuth0Token = (token: string | null | undefined): boolean => {
  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwt_decode<JwtPayload>(token);
    const issuer = decodedToken?.iss;
    if (!issuer) {
      return false;
    }

    return auth0Domains.includes(issuer);
  } catch (e) {
    return false;
  }
};
