export const UNSUPPORTED_SIGNATURE_MESSAGE_FORMATS = [
  'yelp',
  'system',
  'voice',
  'multichoice',
  'note',
  'assignment',
  'missedcall',
  'outboundproxy',
  'outboundconference',
  'loggedcall',
  'rostarted',
  'rocompleted',
  'paymentrequest',
];

export const SUPPORTED_SIGNATURE_MESSAGE_FORMATS = ['text', 'media'];
