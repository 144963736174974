import { determineDateBucket } from './determineDateBucket';
import { bucketConversationsFactory } from './bucketConversations';

export type ConversationBuckets = Map<string, Array<any>>;

// @ts-ignore
const getLastMentionedTimestamp = c => {
  if (c.last_mentioned_timestamp) {
    return c.last_mentioned_timestamp;
  }

  return c.lastMentionedTimestamp;
};

export const bucketConversationsByLastMention = bucketConversationsFactory(
  getLastMentionedTimestamp,
  determineDateBucket,
  true,
);
