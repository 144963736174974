import escapeRegExp from 'lodash/escapeRegExp';

/*
  Used in embolden preview to tokenize search term matches in a string
*/
export const getTargetedContent = (
  pattern: string,
  text: string,
  alreadyEscaped = false,
) => {
  const finalPattern = alreadyEscaped ? pattern : escapeRegExp(pattern);
  const regEx = new RegExp(finalPattern, 'gi');
  const matches = text.matchAll(regEx);
  let targetedContent = '';

  for (const match of matches) {
    // @ts-ignore
    const term = text.substring(match.index, match.index + pattern.length);
    targetedContent = text.replace(term, `**${term}**`);
  }

  return targetedContent.split('**');
};
