import {
  DateTimeFormats,
  diffDates,
  formatDateTime,
  isDateSame,
  utcDate,
} from './dateTime';

export const readableTimestamp = (
  timestamp: Date | null | undefined,
  future = false,
) => {
  if (!timestamp) {
    return '';
  }

  const then = utcDate(timestamp);
  const now = utcDate();
  const mismatchTime = (future && then < now) || (!future && then > now);

  // On the off chance that our system message is ahead of time, render
  // something sane.
  if (diffDates(then, now, 'minute') <= 1 || mismatchTime) {
    return 'Now';
  }

  if (isDateSame(then, now, 'day')) {
    // ex. 11:15 am
    return formatDateTime(timestamp, DateTimeFormats.TIME);
  }

  if (isDateSame(then, now, 'year') && !future) {
    // ex. Fri, 3/5 11:15 am
    return formatDateTime(timestamp);
  }

  // ex. Fri, 3/5/22 11:15 am
  return formatDateTime(timestamp, DateTimeFormats.LONG);
};
