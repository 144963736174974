import { $Values } from 'utility-types';
import {
  DateTimeFormats,
  adjustDateByIntervals,
  diffAs,
  diffDates,
  formatDateTime,
  isDateSame,
  toTimezone,
} from './dateTime';

export const TIME_BUCKETS = {
  JUST_NOW: 'Just Now',
  LAST_2_HOURS: 'Last 2 hours',
  LAST_6_HOURS: 'Last 6 hours',
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  LAST_7_DAYS: 'Last 7 Days',
  LAST_15_DAYS: 'Last 15 Days',
  THIS_MONTH: 'This Month',
};

export type BucketKey = $Values<typeof TIME_BUCKETS> | string;

export const determineDateBucket = (
  timestamp: Date,
  nowDate: Date,
  timezone: string,
  buckets: typeof TIME_BUCKETS = TIME_BUCKETS,
): BucketKey => {
  const then = toTimezone(timestamp, timezone);
  const now = toTimezone(nowDate, timezone);

  const diff = diffDates(nowDate, timestamp);

  if (then > now) {
    // Message from ~~~ the FuTuRE ~~~
    return buckets.JUST_NOW;
  }

  if (isDateSame(nowDate, then, 'day')) {
    if (diffAs(diff, 'minute') < 15) {
      return buckets.JUST_NOW;
    }
    const hours = diffAs(diff, 'hour');
    if (hours < 2) {
      return buckets.LAST_2_HOURS;
    }
    if (hours < 6) {
      return buckets.LAST_6_HOURS;
    }
    return buckets.TODAY;
  }

  const yesterday = adjustDateByIntervals(-1, 'day', now);

  if (isDateSame(yesterday, then, 'day')) {
    return buckets.YESTERDAY;
  }

  const days = diffAs(diff, 'day');
  if (days < 7) {
    return buckets.LAST_7_DAYS;
  }

  if (days < 15) {
    return buckets.LAST_15_DAYS;
  }

  if (isDateSame(now, then, 'month')) {
    return buckets.THIS_MONTH;
  }

  // ex. October 2018
  return formatDateTime(then, DateTimeFormats.MONTH_YEAR);
};
