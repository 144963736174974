export const isValidEmail = (email: string | null | undefined) => {
  if (!email) {
    return false;
  }
  if (!/^[A-Z0-9._%+\-\\']+@[A-Z0-9\-.]+\.[A-Z]{2,}$/i.test(email)) {
    return false;
  }
  return true;
};

export const isValidPassword = (password: string) => {
  if (password.length < 8) {
    return false;
  }

  return Boolean(password.match(/[a-z]/i) && password.match(/[0-9]/i));
};
export default isValidEmail;
