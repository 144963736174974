import {
  normalizedDate,
  SECONDS_IN_AN_HOUR,
  SECONDS_IN_A_DAY,
  SECONDS_IN_A_MINUTE,
} from './dateTime';

type Timestamp = Date | string;

export const waitingSinceTimestamp = (
  timestamp: Timestamp | null | undefined,
) => {
  if (!timestamp) {
    return '--';
  }

  const then = normalizedDate(timestamp);
  const now = normalizedDate();
  const diff = now.getTime() - then.getTime();
  const diffInSeconds = diff / 1000;

  // On the off chance that our system message is ahead of time, render something sane
  if (diffInSeconds < SECONDS_IN_A_MINUTE) {
    return 'a few seconds ago';
  }
  if (diffInSeconds < SECONDS_IN_AN_HOUR) {
    return `${Math.floor(diffInSeconds / SECONDS_IN_A_MINUTE)} min`;
  }
  if (diffInSeconds < SECONDS_IN_A_DAY) {
    return `${Math.floor(diffInSeconds / SECONDS_IN_AN_HOUR)} hr`;
  }
  if (diffInSeconds < SECONDS_IN_A_DAY * 2) {
    return '1 day';
  }
  return `${Math.floor(diffInSeconds / SECONDS_IN_A_DAY)} days`;
};
