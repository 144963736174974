import isEmpty from 'lodash/isEmpty';

// @ts-ignore
const formatCurrency = (amount, locale = 'en-US', currency = 'USD') => {
  const priceFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

  return priceFormatter.format(amount / 100);
};

// @ts-ignore
const formatCurrencyInput = value => {
  if (isEmpty(value)) {
    return '';
  }

  const valueStripped = value.replace(/[^0-9.]/g, '');
  const valueFloat = Number.parseFloat(valueStripped);
  if (Number.isNaN(valueFloat) || !Number.isFinite(valueFloat)) {
    // This shouldn't be possible after stripping out characters, but just in case
    return '0.00';
  }
  const valueParts = valueStripped.split('.');
  let updatedValue;
  if (!valueParts || valueParts.length <= 1) {
    updatedValue = valueStripped;
  } else {
    // + in front of valueParts[0] trims leading zeros by converting to integer
    updatedValue = `${+valueParts[0]}.${valueParts[1].substring(0, 2)}`;
  }

  return updatedValue;
};

// eslint-disable-next-line import/prefer-default-export
export { formatCurrency, formatCurrencyInput };
