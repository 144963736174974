import sortBy from 'lodash/sortBy';

import type { BucketKey } from './determineDateBucket';
import { determineDateBucket } from './determineDateBucket';
import { getCurrentTimezone } from './dateTime';

export type ConversationBuckets = Map<BucketKey, Array<any>>;

export const bucketConversationsFactory =
  (
    sortingCriteriaFn: (conversation: any) => any,
    determineBucketFn: (
      timestamp: Date,
      nowDate: Date,
      timezone: string,
    ) => BucketKey,
    reverse: boolean,
  ) =>
  (
    conversations: any,
    now: Date = new Date(),
    timezone: string = getCurrentTimezone(),
  ): ConversationBuckets => {
    // sortBy defaults to ascending order, so reverse it to get the most
    // recently updated first
    let sortedConversations = sortBy(conversations, sortingCriteriaFn);

    if (reverse) {
      sortedConversations = sortedConversations.reverse();
    }

    // Use a map since it preserves insertion order when iterating.
    const buckets = new Map<string, Array<any>>();
    // @ts-ignore
    let lastBucket = null;

    sortedConversations.forEach(conversation => {
      const currentBucket = determineBucketFn(
        sortingCriteriaFn(conversation),
        now,
        timezone,
      );

      // @ts-ignore
      if (currentBucket !== lastBucket) {
        // @ts-ignore
        buckets.set(currentBucket, []);
        lastBucket = currentBucket;
      }

      const conversationBucket = buckets.get(currentBucket);
      if (conversationBucket) {
        conversationBucket.push(conversation);
        buckets.set(currentBucket, conversationBucket);
      }
    });

    return buckets;
  };

// @ts-ignore
const getLastUpdated = c => {
  if (c.last_updated) {
    return c.last_updated;
  }

  return c.lastUpdated;
};

export const bucketConversations = bucketConversationsFactory(
  getLastUpdated,
  determineDateBucket,
  true,
);
