import { diffDates, startOfDay } from './dateTime';

export const readableDeltaTimestamp = (timestamp: Date | null | undefined) => {
  if (!timestamp) return '';

  const now = startOfDay();
  const then = startOfDay(timestamp);
  const diffDays = diffDates(now, then, 'day', false);
  const diffWeeks = diffDates(now, then, 'week', false);
  const diffMonths = diffDates(now, then, 'month', false);

  if (diffMonths >= 2) return `In ${diffMonths} months`;
  if (diffWeeks >= 8) return `In ${diffWeeks} weeks`;
  if (diffMonths === 1) return 'In 1 month';
  if (diffWeeks >= 2) return `In ${diffWeeks} weeks`;
  if (diffDays > 1) return `In ${diffDays} days`;
  if (diffDays === 1) return 'Tomorrow';
  if (diffDays === 0) return 'Today';
  if (diffDays === -1) return '1 day ago';
  if (diffDays > -7) return `${-diffDays} days ago`;
  if (diffWeeks === -1) return '1 week ago';
  if (diffWeeks > -4) return `${-diffWeeks} weeks ago`;
  if (diffMonths === -1) return '1 month ago';
  if (diffMonths < -1) return `${-diffMonths} months ago`;
  return '';
};
