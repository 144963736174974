export function convertMinutesToDHM(minutesToWait: number) {
  if (!minutesToWait) {
    return '0 minutes';
  }

  const d = Math.floor(minutesToWait / 1440);
  const h = Math.floor((minutesToWait / 60) % 24);
  const m = Math.floor(minutesToWait % 60);
  const dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : '';
  const hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';

  return (dDisplay + hDisplay + mDisplay).trim();
}
