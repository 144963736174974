import {
  SECONDS_IN_AN_HOUR,
  SECONDS_IN_A_DAY,
  SECONDS_IN_A_MINUTE,
} from './dateTime';

const getTimeFromSeconds = (n: number) => {
  const totalSeconds = Math.ceil(n);
  const days = Math.floor(totalSeconds / SECONDS_IN_A_DAY);
  const hours = Math.floor(
    (totalSeconds % SECONDS_IN_A_DAY) / SECONDS_IN_AN_HOUR,
  );

  const minutes = Math.floor(
    (totalSeconds % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE,
  );

  const seconds = Math.floor(totalSeconds % SECONDS_IN_A_MINUTE);

  return {
    seconds,
    minutes,
    hours,
    days,
  };
};

const padNumber = (n: number) => {
  return n.toString().padStart(2, '0');
};

export const getFormattedTimeFromSeconds = (totalSeconds: number) => {
  const { seconds, minutes, hours } = getTimeFromSeconds(totalSeconds);

  if (totalSeconds < SECONDS_IN_AN_HOUR) {
    return `${minutes}:${padNumber(seconds)}`;
  }
  return `${hours}:${padNumber(minutes)}:${padNumber(seconds)}`;
};
