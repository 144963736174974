export const isUnassigned = (label: string) =>
  label.toLowerCase() === 'nobody' || label.toLowerCase() === 'unassigned';

export const getAvatarInitials = (label: string) => {
  if (isUnassigned(label)) {
    return '- -';
  }

  const name = label.toUpperCase();

  const split = name.split(' ');

  if (split.length === 1) {
    // Label is an email or a single-named celebrity, take the first letter
    return split[0].slice(0, 1);
  }

  if (split.length === 2) {
    // standard first name, last name case
    return split[0].slice(0, 1) + split[1].slice(0, 1);
  }

  return split[0].slice(0, 1) + split[split.length - 1].slice(0, 1);
};
