import { RoundingStrategy, diffAs, diffDates } from './dateTime';

export const determineWaitBucket = (
  timestamp: Date | null | undefined,
  now: Date,
) => {
  if (!timestamp) {
    return 'Replied';
  }

  const diff = diffDates(now, timestamp);

  if (timestamp > now) {
    // Message from ~~~ the FuTuRE ~~~
    return 'less than one hour';
  }

  if (diffAs(diff, 'minute') < 60) {
    return 'less than one hour';
  }

  const hours = diffAs(diff, 'hour');
  if (hours < 2) {
    return '1 hour';
  }

  const days = diffAs(diff, 'day');
  if (days < 1) {
    return `${hours} hours`;
  }

  if (days === 1) {
    return `1 day`;
  }

  const weeks = diffAs(diff, 'week', RoundingStrategy.ROUND);
  if (weeks < 1) {
    return `${days} days`;
  }

  if (weeks === 1) {
    return `1 week`;
  }

  const months = diffAs(diff, 'month');
  if (months < 1) {
    return `${weeks} weeks`;
  }

  if (months === 1) {
    return `1 month`;
  }

  if (diffAs(diff, 'year') < 1) {
    return `${months} months`;
  }

  return 'more than a year';
};
