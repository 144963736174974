export const getBaseUrl = () => {
  /*
   * Try to load from the VITE_BASE_URL env variable
   * If that isn't set (likely on production), use empty string
   * because the default host will be the one our
   * javascript was fetched from.
   */

  let url = process.env.VITE_BASE_URL;
  if (!url) {
    url = '';
  } else {
    console.log(`dev override found using ${url}`);
  }
  return url;
};
