const DELIMITER = '|||';

// Copied from MDN docs:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
export function escapeRegExp(text: string) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/* Don't look too closely at this code, it'll
   be re-written soon (famous last words). */
const addDelimiter = (content: string, tag: string) => {
  const preDelimiter = /((?!(\|)).{1}|^)/;
  const postDelimiter = /(?!\|\|\|)/;

  // Replace all occurrences of the tag that have not previously been replaced.
  // A tag has already been replaced if it is delimited: e.g. |||foo|||
  const tagRegex = new RegExp(
    `${preDelimiter.source}@${escapeRegExp(tag)}${postDelimiter.source}`,
    'g',
  );

  return content.replace(tagRegex, ` ${DELIMITER}@${tag}${DELIMITER}`);
};

/*
 * Split a message list into an array of tokens, where each mention is
 * a string at an index. The tokenization isn't perfect: what's important
 * here is that each mention is its own index.
 */
export const tokenizeMentions = (
  content: string,
  tags: Array<string>,
): Array<string> => {
  const delimittedLines = content.split('\n').map(line => {
    let delimitedContent = line;
    // Sort the tags in descending order and wrap each tag in its delimiter. This
    // allows us to avoid the case where we replace Foo first then encounter a
    // FooBar, see that it has already been replaced, and skip it.
    [...tags]
      .sort()
      .reverse()
      .forEach(tag => {
        delimitedContent = addDelimiter(delimitedContent, tag);
      });
    return delimitedContent;
  });

  const split = delimittedLines.join('\n').split(DELIMITER);

  if (split[0] === ' ') {
    return split.slice(1);
  }

  return split;
};
