import isEmpty from 'lodash/isEmpty';
import {
  pad2Digits,
  SECONDS_IN_AN_HOUR,
  SECONDS_IN_A_MINUTE,
} from './dateTime';

export type OpenHours = {
  opens?: string;
  closes?: string;
  weekday: number;
};

export type HoursByWeekday = {
  Friday: Array<OpenHours>;
  Monday: Array<OpenHours>;
  Saturday: Array<OpenHours>;
  Sunday: Array<OpenHours>;
  Thursday: Array<OpenHours>;
  Tuesday: Array<OpenHours>;
  Wednesday: Array<OpenHours>;
};

export const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
];

export const WEEKEND = ['Saturday', 'Sunday'];

export const DAYS_OF_WEEK = [...WEEKDAYS, ...WEEKEND];

export const flattenHours = (hours: HoursByWeekday): Array<OpenHours> => {
  const openHours: Array<OpenHours> = [];
  Object.keys(hours).forEach(day => {
    // @ts-ignore
    if (!isEmpty(hours[day])) {
      // @ts-ignore
      hours[day].forEach(shift => openHours.push(shift));
    }
  });
  return openHours;
};

export const extractOpenHours = (
  regular: Array<OpenHours> | null | undefined,
): HoursByWeekday => {
  /* Converts OpenHour schema into a mapping by day of week
    if regular is falsey, returns default hours
  */
  const defaultHours = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  };

  if (!regular) {
    return defaultHours;
  }
  return regular.reduce((days, item) => {
    // @ts-ignore
    days[DAYS_OF_WEEK[item.weekday]].push(item);
    return days;
  }, defaultHours);
};

const oneHour = 60 * 60;
const oneMinute = 60;

export const durationStr = (duration: number): string => {
  let result;
  if (duration >= oneHour) {
    const hours = Math.floor(duration / oneHour);
    const minutes = Math.floor((duration - hours * oneHour) / oneMinute);
    result = `${hours}hr${hours > 1 ? 's' : ''}`;
    if (minutes) {
      result = `${result} ${minutes}m`;
    }
  } else if (duration >= oneMinute) {
    const minutes = Math.floor(duration / oneMinute);
    const seconds = Math.floor(duration - minutes * oneMinute);
    result = `${minutes}m`;
    if (seconds) {
      result = `${result} ${seconds}s`;
    }
  } else {
    result = `${duration}s`;
  }

  return result;
};

export const paddedDurationString = (duration: number): string => {
  /*  
  As this is used to display video length only at the moment, getting a negative duration
  would signal an error meaning that the video has no length. If there is a need to use the 
  absolute duration, we will need to make sure this case is handled.
 */
  if (duration < 0) {
    return '0:00';
  }

  const hours = Math.floor(duration / SECONDS_IN_AN_HOUR);
  const minutes = Math.floor(
    (duration % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE,
  );
  const minutesPadded = pad2Digits(minutes);
  const seconds = duration % SECONDS_IN_A_MINUTE;
  const secondsPadded = pad2Digits(seconds);

  if (duration < 3600) {
    return `${minutes}:${secondsPadded}`;
  }

  return `${hours}:${minutesPadded}:${secondsPadded}`;
};

export const convertHourToAmPmHour = (hour: number): string => {
  if (hour === 0) {
    return '12am';
  }

  if (hour < 12) {
    return `${hour}am`;
  }

  if (hour === 12) {
    return '12pm';
  }

  return `${hour - 12}pm`;
};

export const getFullYear = (): number => {
  return new Date().getFullYear();
};
