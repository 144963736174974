import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

const _ = { startCase, toLower };

export const capitalize = (word: string) =>
  _.startCase(_.toLower(word).replace(/_/g, ' '));

export const isNumeric = (value: string) => {
  return /^[0-9]+$/.test(value);
};
