import { determineWaitBucket } from './determineWaitBucket';
import { bucketConversationsFactory } from './bucketConversations';

export type ConversationBuckets = Map<string, Array<any>>;

// @ts-ignore
const getWaitingSince = c => {
  if (c.waiting_since) {
    return c.waiting_since;
  }

  return c.waitingSince;
};

export const bucketConversationsByWait = bucketConversationsFactory(
  getWaitingSince,
  determineWaitBucket,
  false,
);
