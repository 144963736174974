export function formatPhoneNumber(phonenum: string): string {
  if (phonenum.match('^\\+1\\d{10}$')) {
    // +15551110000
    // +1 (555) 111 1000
    // 01  234  567 8901
    return `(${phonenum.slice(2, 5)}) ${phonenum.slice(5, 8)}-${phonenum.slice(
      8,
    )}`;
  }
  return phonenum;
}

export function toE164(mdn: string): string {
  // react-intl doesn't offer any options for formatting phone numbers.
  // google18n (https://github.com/googlei18n) does have a
  // library for doing so (https://github.com/googlei18n/libphonenumber),
  // so it might be worth removing react-intl later. For now, use this cheap
  // method

  // ex. 1-555-000-1111 ==> +15550001111
  const formatted = mdn
    // replace all non-digit characters
    .replace(/\D/g, '');
  if (formatted.startsWith('1')) {
    return `+${formatted}`;
  }
  return formatted ? `+1${formatted}` : '';
}

export const isValidNumber = (number: string): boolean => {
  const phoneNumberRegex = /^\+1[0-9]{10}$/g;
  if (number === '') {
    return false;
  }
  if (number.startsWith('+') && number[1] !== '1') {
    return false;
  }
  return phoneNumberRegex.test(toE164(number));
};

export const isPhoneNumberLike = (address: string): boolean => {
  if (/^[0-9+ \-()]+$/g.test(address)) {
    return true;
  }
  return false;
};
export const isValidFormattedNumber = (number: string): boolean => {
  const phoneNumberRegex = /^[0-9]{1,3}-[0-9]{3}-[0-9]{4}$/g;
  return phoneNumberRegex.test(number);
};

export const isValidNumberWithCountryCode = (number: string): boolean => {
  const phoneNumberRegex = /^[0-9]{1,3}-[0-9]{1,3}-[0-9]{3}-[0-9]{4}$/g;
  return phoneNumberRegex.test(number);
};

export const normalizePhone = (
  value: string | null | undefined,
  previousValue: string | null | undefined,
): string | null | undefined => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `${onlyNums}-`;
    }
    if (onlyNums.length === 6) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}-`;
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
};

export const areaCode = (mdn: string) => {
  const intlFormat = toE164(mdn);
  if (!intlFormat.startsWith('+1')) {
    return null;
  }

  return intlFormat.replace('+1', '').substring(0, 3);
};

export const colorForAddress = (address: string | null | undefined) => {
  // Copied from mobile, should be consolidated
  if (!address || address.length <= 1) return '#DDD';
  const lastChar = address[address.length - 1];
  switch (lastChar) {
    case '0':
      return '#7C9885';
    case '1':
      return '#7A5980';
    case '2':
      return '#5C5D8D';
    case '3':
      return '#28666E';
    case '4':
      return '#698996';
    case '5':
      return '#E8D79D';
    case '6':
      return '#B2CEDE';
    case '7':
      return '#A8C69F';
    case '8':
      return '#99A1A6';
    case '9':
      return '#749E65';
    default:
      return '#DDDDDD';
  }
};

export const formattedAddress = (address: string, source: string): string => {
  switch (source) {
    case 'facebook':
      return 'via Facebook';
    case 'google':
      return 'via Google My Business';
    case 'sms':
    default:
      return isValidNumber(address) ? formatPhoneNumber(address) : address;
  }
};

const isBandwidthNumber = (lineType: string, carrierName: string) => {
  return lineType === 'VOIP' && carrierName.toLowerCase().includes('bandwidth');
};

const isSybaseNumber = (lineType: string, carrierName: string) => {
  return (
    lineType === 'VOIP' && carrierName.toLowerCase().includes('sms - sybase365')
  );
};

export const canTextEnablePhoneline = (
  lineType: string | null | undefined,
  carrierName: string | null | undefined,
) => {
  if (!lineType || !carrierName) {
    return false;
  }

  if (lineType === 'NUMA') {
    return true;
  }

  return (
    ['LANDLINE', 'VOIP'].includes(lineType) &&
    !isBandwidthNumber(lineType, carrierName) &&
    !isSybaseNumber(lineType, carrierName)
  );
};
