export const MILLIS_IN_A_SECOND = 1000;
export const SECONDS_IN_A_MINUTE = 60;
export const SECONDS_IN_AN_HOUR = SECONDS_IN_A_MINUTE * 60;
export const SECONDS_IN_A_DAY = SECONDS_IN_AN_HOUR * 24;
const SECONDS_IN_A_WEEK = SECONDS_IN_A_DAY * 7;
const SECONDS_IN_A_MONTH = SECONDS_IN_A_DAY * 30;
const SECONDS_IN_A_YEAR = SECONDS_IN_A_DAY * 365;

export enum DateTimeFormats {
  REGULAR = 'ddd, M/D h:mm a',
  SHORT = 'ddd h:mm a',
  LONG = 'ddd, M/D/YY h:mm a',
  TIME = 'h:mm a',
  DATE = 'ddd M/D',
  DAY = 'dddd',
  SHORT_DATE = 'M/D',
  LONG_ALT = 'MMM D, h:mm a',
  CALENDAR = 'MMMM D, YYYY',
  MONTH_YEAR = 'MMMM YYYY',
}

export const RoundingStrategy = {
  ROUND: (num: number) => Math.round(num),
  FLOOR: (num: number) => Math.floor(num),
  NONE: (num: number) => num,
};

// eslint-disable-next-line
type DiffRoundingStrategy =
  (typeof RoundingStrategy)[keyof typeof RoundingStrategy];

const DateFormatFunctions: Record<
  DateTimeFormats,
  (date: Date, timeZone?: string) => string
> = {
  [DateTimeFormats.REGULAR]: (date, timeZone) =>
    date.toLocaleString('en-US', {
      weekday: 'short',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone,
    }),
  [DateTimeFormats.SHORT]: (date, timeZone) =>
    date.toLocaleDateString('en-US', {
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone,
    }),
  [DateTimeFormats.LONG]: (date, timeZone) =>
    date.toLocaleString('en-US', {
      weekday: 'short',
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone,
    }),
  [DateTimeFormats.TIME]: (date, timeZone) =>
    date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone,
    }),
  [DateTimeFormats.DATE]: (date, timeZone) =>
    date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'numeric',
      day: 'numeric',
      timeZone,
    }),
  [DateTimeFormats.DAY]: (date, timeZone) =>
    date.toLocaleDateString('en-US', { weekday: 'long', timeZone }),
  [DateTimeFormats.SHORT_DATE]: (date, timeZone) =>
    date.toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      timeZone,
    }),
  [DateTimeFormats.LONG_ALT]: (date, timeZone) => {
    const datePart = date.toLocaleString('en-US', {
      timeZone,

      month: 'short',
      day: 'numeric',
    });
    const timePart = date.toLocaleTimeString('en-US', {
      timeZone,

      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return `${datePart}, ${timePart.toLowerCase()}`;
  },
  [DateTimeFormats.CALENDAR]: (date, timeZone) =>
    date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      timeZone,
    }),
  [DateTimeFormats.MONTH_YEAR]: (date, timeZone) =>
    date.toLocaleString('en-US', {
      month: 'long',
      year: 'numeric',
      timeZone,
    }),
};

type DiffUnit =
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year';

export const isValidDate = (
  date: Date | string | undefined | null,
): boolean => {
  const dateObj = date ? new Date(date) : null;
  return dateObj instanceof Date && !Number.isNaN(dateObj.valueOf());
};

export const normalizedDate = (date?: Date | string) => {
  if (!isValidDate(date)) {
    return new Date();
  }
  const internalDate = date ? new Date(date) : new Date();
  return new Date(internalDate.toISOString());
};

export const utcDate = (date?: Date | string) =>
  new Date(normalizedDate(date).toUTCString());
export const pad2Digits = (num: number | string) =>
  num.toString().padStart(2, '0');

export const formatDateTime = (
  date: Date | string,
  format: DateTimeFormats = DateTimeFormats.REGULAR,
  timezone?: string,
): string => {
  const formattedDate = DateFormatFunctions[format](
    normalizedDate(date),
    timezone,
  );

  let adjustedFormattedDate =
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  adjustedFormattedDate = adjustedFormattedDate.replace(/am|pm/i, match =>
    match.toUpperCase(),
  );

  if (
    [
      DateTimeFormats.LONG_ALT,
      DateTimeFormats.DAY,
      DateTimeFormats.CALENDAR,
      DateTimeFormats.MONTH_YEAR,
    ].includes(format)
  ) {
    return adjustedFormattedDate;
  }

  return adjustedFormattedDate.replace(/,/g, (match, offset) =>
    offset > 3 ? '' : match,
  );
};

export const diffAs = (
  seconds: number,
  unit: DiffUnit,
  roundingStrategy: DiffRoundingStrategy = RoundingStrategy.FLOOR,
) => {
  switch (unit) {
    case 'minute':
      return roundingStrategy(seconds / SECONDS_IN_A_MINUTE);
    case 'hour':
      return roundingStrategy(seconds / SECONDS_IN_AN_HOUR);
    case 'day':
      return roundingStrategy(seconds / SECONDS_IN_A_DAY);
    case 'week':
      return roundingStrategy(seconds / SECONDS_IN_A_WEEK);
    case 'month':
      return roundingStrategy(seconds / SECONDS_IN_A_MONTH);
    case 'quarter':
      return roundingStrategy(seconds / (SECONDS_IN_A_MONTH * 3));
    case 'year':
      return roundingStrategy(seconds / SECONDS_IN_A_YEAR);
    default:
      return roundingStrategy(seconds);
  }
};

export const toTimezone = (date: Date | string, timezone: string) => {
  const newDate = normalizedDate(date).toLocaleString('en-US', {
    timeZone: timezone,

    // Redundant from the 'en-US' locale,
    // but it was seen as different from the default by at least one person
    hour12: true,
  });

  const [dateString, timeString] = newDate.split(', ');
  const [month, day, year] = dateString.split('/');
  const amPmIndex = timeString.length - 2;
  const newTimeString = timeString.substring(0, amPmIndex - 1);
  const amPm = timeString.substring(amPmIndex);
  const [hours, minutes, seconds] = newTimeString.split(':');
  const hoursZeroBased = hours === '12' ? '0' : hours;
  const newHours =
    Number.parseInt(hoursZeroBased, 10) + (amPm === 'PM' ? 12 : 0);

  const newDateString = `${year}-${pad2Digits(month)}-${pad2Digits(day)}`;

  const dateTimeString = `${newDateString}T${pad2Digits(
    newHours,
  )}:${minutes}:${seconds}`;

  return new Date(dateTimeString);
};

export const startOfDay = (date?: Date) => {
  const newDate = normalizedDate(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

// Monday is the first day of the week
export const startOfWeek = (date?: Date) => {
  const newDate = startOfDay(date);
  newDate.setDate(newDate.getDate() - newDate.getDay() + 1);
  return newDate;
};

export const adjustDateByIntervals = (
  numIntervals: number,
  unit: DiffUnit,
  initialDate = new Date(),
): Date => {
  const newDate = normalizedDate(initialDate);

  switch (unit) {
    case 'minute':
      newDate.setMinutes(newDate.getMinutes() + numIntervals);
      return newDate;
    case 'hour':
      newDate.setHours(newDate.getHours() + numIntervals);
      return newDate;
    case 'day':
      newDate.setDate(newDate.getDate() + numIntervals);
      return newDate;
    case 'week':
      newDate.setDate(newDate.getDate() + numIntervals * 7);
      return newDate;
    case 'month':
      /* If the last day of the current month is higher than the last day of the target month
         it'll go to the next month (April 31st -> May 1st --- 30 + 1 day)
         We have to set the day as well to correct the problem
         0 will correctly give us the last day, but we need to overshoot the month by 1 to get it
      */
      if (
        newDate.getDate() === 31 ||
        (newDate.getMonth() + numIntervals === 1 && newDate.getDate() > 29)
      ) {
        newDate.setMonth(newDate.getMonth() + numIntervals + 1, 0);
        return newDate;
      }

      newDate.setMonth(newDate.getMonth() + numIntervals);

      return newDate;
    case 'quarter':
      return adjustDateByIntervals(numIntervals * 3, 'month', newDate);
    case 'year':
      newDate.setFullYear(newDate.getFullYear() + numIntervals);
      return newDate;
    default:
      return newDate;
  }
};

export const getQuarter = (date: Date) => Math.floor((date.getMonth() + 3) / 3);

export const startOf = (date: Date, unit: DiffUnit) => {
  const newDate = normalizedDate(date);
  switch (unit) {
    case 'minute':
      newDate.setSeconds(0);
      return newDate;
    case 'hour':
      newDate.setMinutes(0, 0);
      return newDate;
    case 'day':
      return startOfDay(newDate);
    case 'week':
      return startOfWeek(newDate);
    case 'month':
      newDate.setDate(1);
      return startOfDay(newDate);
    case 'quarter':
      newDate.setMonth(getQuarter(newDate) * 3 - 3, 1);
      return startOfDay(newDate);
    case 'year':
      newDate.setMonth(0, 1);
      return startOfDay(newDate);
    default:
      return newDate;
  }
};

export const endOf = (date: Date, unit: DiffUnit): Date => {
  const newDate = normalizedDate(date);

  switch (unit) {
    case 'minute':
      newDate.setSeconds(59);
      return newDate;
    case 'hour':
      newDate.setMinutes(59, 59);
      return newDate;
    case 'day':
      newDate.setHours(23, 59, 59, 999);
      return newDate;
    case 'week':
      // Since start to the week is Monday, we need to add 7 days to get to Sunday
      newDate.setDate(newDate.getDate() + 7 - newDate.getDay());
      return endOf(newDate, 'day');
    case 'month':
      newDate.setMonth(newDate.getMonth() + 1, 0);
      return endOf(newDate, 'day');
    case 'quarter':
      newDate.setMonth(getQuarter(newDate) * 3, 0);
      return endOf(newDate, 'day');
    case 'year':
      newDate.setMonth(11, 31);
      return endOf(newDate, 'day');
    default:
      return newDate;
  }
};

export const diffDates = (
  date1: Date,
  date2: Date,
  unit: DiffUnit = 'second',
  absoluteValue = true,
) => {
  const d1 =
    unit === 'quarter' ? startOf(date1, 'quarter') : normalizedDate(date1);
  const d2 =
    unit === 'quarter' ? startOf(date2, 'quarter') : normalizedDate(date2);

  const diff = (d2.getTime() - d1.getTime()) / MILLIS_IN_A_SECOND;
  return absoluteValue ? Math.abs(diffAs(diff, unit)) : diffAs(diff, unit);
};

export const getCurrentTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimezoneAbbreviation = (date: Date, timezone: string) => {
  return normalizedDate(date)
    .toLocaleString('en-US', {
      timeZone: timezone,
      timeZoneName: 'short',
    })
    .split(' ')
    .reverse()[0];
};

export const isDateSame = (
  date1: Date,
  date2: Date,
  unit: 'day' | 'month' | 'year',
) => {
  const d1 = normalizedDate(date1);
  const d2 = normalizedDate(date2);

  if (unit === 'day' && d1.getDate() !== d2.getDate()) {
    return false;
  }
  if (['month', 'day'].includes(unit) && d1.getMonth() !== d2.getMonth()) {
    return false;
  }

  if (d1.getFullYear() !== d2.getFullYear()) {
    return false;
  }

  return true;
};
