// @ts-ignore
export function getPaymentAmount(paymentRequest) {
  if (
    paymentRequest.captureMethod === 'manual' &&
    ['SUCCESS', 'REFUND', 'PARTIAL_REFUND'].includes(paymentRequest.status)
  ) {
    return paymentRequest.amountCaptured;
  }
  return paymentRequest.amount;
}
